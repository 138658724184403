@import '../../assets/css/shared.scss';

.cp-tooltip {
  font-family: 'flamabook', 'sans-serif';
  font-size: 16px;
  font-weight: 400;
  background-color: $tooltip-color;
  color: #ffffff;
  opacity: 1 !important;
}

.bs-tooltip-top {
  .arrow {
    bottom: 0;

    &::before {
      border-top-color: $tooltip-color;
    }
  }
}
