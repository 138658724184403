@import './shared.scss';

html,
body {
  font-family: $font-cepsa-primary;
  height: fit-content;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0;
  font-weight: normal;
  padding-top: 0 !important;
  background: $background-color;
  margin: 0;
  color: $grey-dark-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

#root {
  width: 100%;
  background: $background-color;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-right: 5px;
  padding-left: 5px;
}

:focus {
  outline: none;
}

.cp-initial-loader {
  position: relative;
  float: left;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: table;

  img {
    background-color: $red-color;
    display: table-cell;
    vertical-align: middle;
  }

  span {
    padding-left: 10px;
    font-family: $font-cepsa-primary;
    font-size: 34px;
    font-weight: 500;
    display: table-cell;
    vertical-align: middle;
  }
}

/* Base layout */
.cp-base-layout {
  padding: 0;
  background: $cp-base-layout-background-color;
  overflow: hidden;
  height: 100%;

  .mock-mode-indicator {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $mock-mode-indicator-background-color;
  }
}

/* Wrapper que llevan todas las páginas de contenido */

.cp-page-wrapper {
  background-color: #fff !important;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  width: calc(100% - #{$menu-collapsed});
  margin-left: $menu-collapsed;

  .page-content {
    display: flex;
  }
}
