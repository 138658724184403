@import '../../assets/css/shared.scss';

.cp-navbar {
  padding: 0;
  border: none;
  background-color: $grey-light-third;
  z-index: 1;
  width: 255px;
  position: fixed;
  height: 100%;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;

  .navbar-brand {
    padding: 0;
    text-align: left;
    background: $cp-nav-bar-logo-background-color;
    margin: 0;
    position: absolute;
    top: 0;
    width: 70px;
    height: 70px;

    a {
      display: block;
      transition: all 0.3s;
      -moz-transition: all 0.3s;
      -webkit-transition: all 0.3s;
      -o-transition: all 0.3s;
      overflow: hidden;
    }

    img {
      display: none;
    }

    &:before {
      content: '\e91c';
      font-family: 'Pricing-Red' !important;
      font-size: 31px;
      color: white;
      display: block;
      margin-top: 24px;
      margin-left: 17px;
    }
  }

  .navbar-nav {
    position: absolute;
    top: 90px;
    width: inherit;
    list-style: none;
    padding: 0;

    .nav-item {
      position: relative;

      .icon {
      }
    }

    .nav-item a {
      padding: 30px 0;
      height: 90px;

      .icon-optimize {
        font-size: 26px;
        margin-left: 4px;
      }

      .icon-alarms {
        font-size: 19px;
        margin-left: 3px;
      }

      .icon-charts {
        font-size: 20px;
        margin-left: 0px;
      }
    }

    .nav-item.active a:after {
      display: none;
      content: '';
      width: 0;
      height: 0;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      border-right: 15px solid #ffffff;
      position: absolute;
      right: 0;
      top: 18px;
    }

    .nav-item a:hover {
      color: $red-color;
      text-decoration: none;
    }

    .nav-item a:active {
      color: $red-color;
      text-decoration: none;
    }

    .nav-item a span {
      display: block;
    }

    .nav-item a .span2 {
      margin-left: 10px;
      margin-right: 15px;
      width: 30px;
    }

    .nav-item a i {
      display: block;
      text-align: center;
    }
  }

  ul li {
    font-size: 17px;
  }

  .nav-item {
    a {
      color: $cp-nav-bar-font-color;
      transition: all 0.3s;
      text-align: center;
      display: block;
      white-space: nowrap;

      &.active {
        color: $red-color;
      }
    }
  }

  .dropdown-toggle {
    &.no-caret::after {
      display: none;
    }
  }
}

.cp-navbar-collapsed {
  width: $menu-collapsed;
}
