$background-color: #ffffff;
$font-color: #4a4b4d;
$red-color: #ed1d24;

$menu-collapsed: 70px;
$page-content-padding: 50px;

// fonts
$font-cepsa-primary: 'flamamedium', sans-serif;

// cp-base-layout
$cp-base-layout-background-color: #ffffff;

// mock-mode-indicator
$mock-mode-indicator-background-color: #bbbbbc;

// tooltip
$tooltip-color: #37393b;

// cp-nav-bar
$cp-nav-bar-background-color: #eeeeec;
$cp-nav-bar-logo-background-color: #616161;
$cp-nav-bar-font-color: #4a4b4d;

// loading
$loading-background-color: #f7f8f8;
$loading-color: #37393b;

// Colors
$cepsa-color: #ed1d24;
$background-primary: #f2f4f4;
$background-secondary: #e5e9e9;
$main-grey: #37393b;
$high-light-green: #00d100;

// Grey Colors
$grey-light-primary: #b7bfc6;
$grey-light-secondary: #d6dce0;
$grey-light-third: #e0e3e5;
$grey-light-fourth: #f0f3f4;
$grey-dark-primary: #373941;
$grey-dark-secondary: #818790;

// Icons
$ico-webfont-family: 'Pricing-Red' !default;
$ico-webfont-path: '../fonts' !default;
