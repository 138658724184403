@import 'variables';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?kxdb69');
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?kxdb69#iefix')
      format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?kxdb69')
      format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?kxdb69')
      format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?kxdb69##{$icomoon-font-family}')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-performance {
  &:before {
    content: $icon-performance;
  }
}

.icon-calendar {
  &:before {
    content: $icon-caledar;
  }
}

.icon-alarms {
  &:before {
    content: $icon-alarms;
  }
}
.icon-error-guardar-propuestadash {
  &:before {
    content: $icon-error-guardar-propuestadash;
  }
}
.icon-ok-propuesta-guardada-contizacionesdash {
  &:before {
    content: $icon-ok-propuesta-guardada-contizacionesdash;
  }
}
.icon-fechas-regla {
  &:before {
    content: $icon-fechas-regla;
  }
}
.icon-valor-regla {
  &:before {
    content: $icon-valor-regla;
  }
}
.icon-tipo-regla {
  &:before {
    content: $icon-tipo-regla;
  }
}
.icon-titulo-regla {
  &:before {
    content: $icon-titulo-regla;
  }
}
.icon-download {
  &:before {
    content: $icon-download;
  }
}
.icon-optimal-check {
  &:before {
    content: $icon-optimal-check;
  }
}
.icon-results {
  &:before {
    content: $icon-results;
  }
}
.icon-menu-button {
  &:before {
    content: $icon-menu-button;
  }
}
.icon-ok-button {
  &:before {
    content: $icon-ok-button;
  }
}
.icon-cancel-button {
  &:before {
    content: $icon-cancel-button;
  }
}
.icon-filter-button {
  &:before {
    content: $icon-filter-button;
  }
}
.icon-calendar-field {
  &:before {
    content: $icon-calendar-field;
  }
}
.icon-edit-table {
  &:before {
    content: $icon-edit-table;
  }
}
.icon-ok-table {
  &:before {
    content: $icon-ok-table;
  }
}
.icon-cancel-table {
  &:before {
    content: $icon-cancel-table;
  }
}
.icon-close-popup {
  &:before {
    content: $icon-close-popup;
  }
}
.icon-calcular-button {
  &:before {
    content: $icon-calcular-button;
  }
}
.icon-reglas-personalizadas {
  &:before {
    content: $icon-reglas-personalizadas;
  }
}
.icon-charts {
  &:before {
    content: $icon-forecast;
  }
}
.icon-mayor-fields {
  &:before {
    content: $icon-mayor-fields;
  }
}
.icon-optimize {
  &:before {
    content: $icon-optimize;
  }
}
.icon-menos-fields {
  &:before {
    content: $icon-menos-fields;
  }
}
.icon-parametros {
  &:before {
    content: $icon-parametros;
  }
}
.icon-arrow-dropdown {
  &:before {
    content: $icon-arrow-dropdown;
  }
}
.icon-segmetacion {
  &:before {
    content: $icon-segmetacion;
  }
}
.icon-arrow-breadcrumb {
  &:before {
    content: $icon-arrow-breadcrumb;
  }
}
.icon-home-breadcrumb {
  &:before {
    content: $icon-home-breadcrumb;
  }
}
.icon-new-button {
  &:before {
    content: $icon-new-button;
  }
}
.icon-prices {
  &:before {
    content: $icon-prices;
  }
}
.icon-dashboard {
  &:before {
    content: $icon-dashboard;
  }
}
.icon-reglas {
  &:before {
    content: $icon-reglas;
  }
}
.icon-propuestas {
  &:before {
    content: $icon-propuestas;
  }
}
.icon-cepsa-logo {
  &:before {
    content: $icon-cepsa-logo;
  }
}

.icon-danger-state-pricing-red {
  &:before {
    content: $icon-valor-regla;
  }
}

.icon-open-data {
  &:before {
    content: $icon-open-data;
  }
}

.icon-forecast-pricing-v7 {
  &:before {
    content: $icon-forecast;
  }
}

.icon-update {
  &:before {
    content: $icon-update;
  }
}
