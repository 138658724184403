@font-face {
  font-family: 'Pricing-Red';
  src: url('../fonts/Pricing-Red.eot');
  src: url('../fonts/Pricing-Red.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Pricing-Red.woff') format('woff'),
    url('../fonts/Pricing-Red.ttf') format('truetype'),
    url('../fonts/Pricing-Red.svg#Pricing-Red') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'flamabasic';
  src: url('../fonts/flama-basic-webfont.eot');
  src: url('../fonts/flama-basic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/flama-basic-webfont.woff2') format('woff2'),
    url('../fonts/flama-basic-webfont.woff') format('woff'),
    url('../fonts/flama-basic-webfont.ttf') format('truetype'),
    url('../fonts/flama-basic-webfont.svg#flamabasic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'flamablack';
  src: url('../fonts/flama-black-webfont.eot');
  src: url('../fonts/flama-black-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/flama-black-webfont.woff2') format('woff2'),
    url('../fonts/flama-black-webfont.woff') format('woff'),
    url('../fonts/flama-black-webfont.ttf') format('truetype'),
    url('../fonts/flama-black-webfont.svg#flamablack') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'flamabold';
  src: url('../fonts/flama-bold-webfont.eot');
  src: url('../fonts/flama-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/flama-bold-webfont.woff2') format('woff2'),
    url('../fonts/flama-bold-webfont.woff') format('woff'),
    url('../fonts/flama-bold-webfont.ttf') format('truetype'),
    url('../fonts/flama-bold-webfont.svg#flamabold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'flamabook';
  src: url('../fonts/flama-book-webfont.eot');
  src: url('../fonts/flama-book-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/flama-book-webfont.woff2') format('woff2'),
    url('../fonts/flama-book-webfont.woff') format('woff'),
    url('../fonts/flama-book-webfont.ttf') format('truetype'),
    url('../fonts/flama-book-webfont.svg#flamabook') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'flamaextrabold';
  src: url('../fonts/flama-extrabold-webfont.eot');
  src: url('../fonts/flama-extrabold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/flama-extrabold-webfont.woff2') format('woff2'),
    url('../fonts/flama-extrabold-webfont.woff') format('woff'),
    url('../fonts/flama-extrabold-webfont.ttf') format('truetype'),
    url('../fonts/flama-extrabold-webfont.svg#flamaextrabold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'flamalight';
  src: url('../fonts/flama-light-webfont.eot');
  src: url('../fonts/flama-light-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/flama-light-webfont.woff2') format('woff2'),
    url('../fonts/flama-light-webfont.woff') format('woff'),
    url('../fonts/flama-light-webfont.ttf') format('truetype'),
    url('../fonts/flama-light-webfont.svg#flamalight') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'flamamedium';
  src: url('../fonts/flama-medium-webfont.eot');
  src: url('../fonts/flama-medium-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/flama-medium-webfont.woff2') format('woff2'),
    url('../fonts/flama-medium-webfont.woff') format('woff'),
    url('../fonts/flama-medium-webfont.ttf') format('truetype'),
    url('../fonts/flama-medium-webfont.svg#flamamedium') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'flamasemibold';
  src: url('../fonts/flama-semibold-webfont.eot');
  src: url('../fonts/flama-semibold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/flama-semibold-webfont.woff2') format('woff2'),
    url('../fonts/flama-semibold-webfont.woff') format('woff'),
    url('../fonts/flama-semibold-webfont.ttf') format('truetype'),
    url('../fonts/flama-semibold-webfont.svg#flamasemibold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'flamathin';
  src: url('../fonts/flama-thin-webfont.eot');
  src: url('../fonts/flama-thin-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/flama-thin-webfont.woff2') format('woff2'),
    url('../fonts/flama-thin-webfont.woff') format('woff'),
    url('../fonts/flama-thin-webfont.ttf') format('truetype'),
    url('../fonts/flama-thin-webfont.svg#flamathin') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'flamaultralight';
  src: url('../fonts/flama-ultralight-webfont.eot');
  src: url('../fonts/flama-ultralight-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/flama-ultralight-webfont.woff2') format('woff2'),
    url('../fonts/flama-ultralight-webfont.woff') format('woff'),
    url('../fonts/flama-ultralight-webfont.ttf') format('truetype'),
    url('../fonts/flama-ultralight-webfont.svg#flamaultralight') format('svg');
  font-weight: normal;
  font-style: normal;
}
