@import '../shared.scss';
/*
.cp-loading-data {
  width: 110px;
  margin: 0 auto;
  display: block;
  margin-top: 20%;
}
*/
.cp-loading-data-mini {
  width: 13px;
  margin: 0 auto;
  display: block;
}

.cp-loading {
  width: 100%;
  height: 100vh;
  background-color: $grey-light-fourth;
  color: var(--loadingColor, $loading-color);
}

.cp-loading-container {
  position: relative;
  float: left;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: table;
  background-color: transparent;
  color: $grey-dark-primary;

  img {
    display: table-cell;
    vertical-align: middle;
  }

  span {
    padding-left: 30px;
    font-family: 'flamamedium', 'sans-serif';
    font-size: 34px;
    font-weight: 500;
    display: table-cell;
    vertical-align: middle;
  }
}

/*
.page-content .cp-loading-data {}

.results-optimization .cp-loading-data {}

.cp-loading-data {
    margin-top: 20%; 
    svg {
        width: 110px;
        margin: 0 auto;
        display: block;
    }
}

@media (max-width: 1700px) and (min-width: 1301px) {

.page-content .cp-loading-data {
      margin-left: 395px;
      width: calc(100% - 395px);
}
.results-area .cp-loading-data {
      margin-left: 0px !important; 
      width: 100% !important;
}
}


@media (max-width:1300px) {

.page-content .cp-loading-data {
      margin-left: 360px;
      width: calc(100% - 360px);
}
.results-area .cp-loading-data {
      margin-left: 0px !important; 
      width: 100% !important;
} 
}


@media (min-width:1701px) {
  .page-content .cp-loading-data {
      margin-left: 420px;
      width: calc(100% - 420px);
}
.results-area .cp-loading-data {
      margin-left: 0px !important; 
      width: 100% !important;
}
}
*/

.cp-loading-data {
  top: 37vh;
  position: fixed;
  svg {
    width: 100px;
    margin: 0 auto;
    display: block;
  }
}

@media (max-width: 1300px) {
  .page-content .cp-loading-data {
    left: 430px;
    width: calc(100% - 430px);
  }
}

@media (max-width: 1700px) and (min-width: 1301px) {
  .page-content .cp-loading-data {
    left: 465px;
    width: calc(100% - 465px);
  }
}

@media (min-width: 1701px) {
  .page-content .cp-loading-data {
    left: 490px;
    width: calc(100% - 490px);
  }
}
