.cp-404 {
  .margins {
    margin-top: 100px;
    margin-left: 400px;
  }
  .size {
    font-size: 90px;
    color: rgb(99, 99, 99);
    line-height: 2.3cm;
  }
}
