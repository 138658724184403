$icomoon-font-family: 'Pricing-Red' !default;
$icomoon-font-path: '../fonts' !default;

$icon-performance: '\e91e';
$icon-alarms: '\e915';
$icon-charts: '\e916';
$icon-error-guardar-propuestadash: '\e901';
$icon-ok-propuesta-guardada-contizacionesdash: '\e902';
$icon-fechas-regla: '\e903';
$icon-valor-regla: '\e904';
$icon-tipo-regla: '\e905';
$icon-titulo-regla: '\e906';
$icon-download: '\e907';
$icon-optimal-check: '\e908';
$icon-results: '\e909';
$icon-menu-button: '\e90a';
$icon-ok-button: '\e90b';
$icon-cancel-button: '\e90c';
$icon-filter-button: '\e90d';
$icon-calendar-field: '\e90e';
$icon-edit-table: '\e90f';
$icon-ok-table: '\e910';
$icon-cancel-table: '\e911';
$icon-close-popup: '\e912';
$icon-calcular-button: '\e913';
$icon-reglas-personalizadas: '\e914';
$icon-mayor-fields: '\e916';
$icon-optimize: '\e917';
$icon-menos-fields: '\e918';
$icon-parametros: '\e919';
$icon-arrow-dropdown: '\e91a';
$icon-segmetacion: '\e91b';
$icon-arrow-breadcrumb: '\e91c';
$icon-home-breadcrumb: '\e91d';
$icon-new-button: '\e91e';
$icon-prices: '\e91f';
$icon-dashboard: '\e920';
$icon-reglas: '\e921';
$icon-propuestas: '\e922';
$icon-cepsa-logo: '\e923';
$icon-open-data: '\e919';
$icon-forecast: '\e91c';
$icon-closepricing: '\e91b';
$icon-caledar: '\e91d';
$icon-update: '\e91a';
